<template>
    <div class="v2-consent">
        <div class="title">민감정보 수집 및 이용 동의</div>
        <div class="content">
            <div class="consent-item m-b-16" v-for="item in consent" :key="item.id">
                <p v-html="item.id" />
                <div v-if="item.content" class="indent" v-html="item.content" />
            </div>
        </div>
        <div class="today m-t-16">
            <span class="m-r-16">계약일: </span>
            <span class="spoqa-f-bold">{{ `${today.y} 년 ${today.m} 월 ${today.d} 일` }}</span>
        </div>
        <div class="name sign-space">
            <div class="left">
                <span>회원성명: </span>
                <span class="m-l-16 spoqa-f-bold" v-html="me.name" />
            </div>
            <SignComponent :signStatus="signStatus" :sign="sign" :idx="3" id="consent-sign" />
        </div>
    </div>
</template>

<script>
import SignComponent from '@/components/app/SignComponent'

export default {
    name: 'ContractConsentV2',
    components: { SignComponent },
    props: {
        sign: String,
        today: Object,
        contractVersion: Object,
        signStatus: Object,
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        consent() {
            return this.contractVersion.consent
        },
    },
}
</script>

<style scoped lang="scss">
.v2-consent {
    padding-bottom: 40px;
    border-bottom: 1px solid $grey-03;
    margin-bottom: 40px;

    .indent {
        margin-left: 16px;
    }
    .title {
        text-align: center;
        margin-bottom: 12px;
        font-size: 18px;
        @include spoqa-f-bold;
    }
    .item {
        display: flex;
        .num {
            width: 5%;
        }
        .content {
            width: 90%;
        }
    }
    .sign-space {
        display: flex;
        align-items: center;
        margin-top: 16px;
    }
}
</style>
